import React from 'react';

const NetlifyForm = () => (
  <form
    method="post"
    name="contact"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    className="contact-form"
    hidden
  >
    <input type="email" name="email" required placeholder="Email" />
    <textarea name="message" />
    <button type="submit">Send</button>
  </form>
);

export default NetlifyForm;
